import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { endPoints } from '../services/endPoints';


/**
 * Hanlde axios error
 * @param error 
 */
const handleAxiosError = (error: any) => {


  // if it's axios cancel signal error, then throw it as it is
  if (axios.isCancel(error)) throw error;

  // If it's axios related error
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.status === 500 && error.response.data?.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(`HTTP error: ${error.message}`);
      }
    } else {
      throw new Error(error.message);
    }
  } else {

    // unexpected error

    throw new Error(`Unexpected error: ${error}`);
  }
};

/**
 * Make request axios custom helper function
 * @param config 
 * @returns 
 */
export const makeRequest = async <T>(
  config: AxiosRequestConfig
): Promise<T> => {

  // Create an axios instance with base URL
  const api = axios.create({
    baseURL: endPoints.base, // Use process.env for environment variables
  });

  try {

    const response: AxiosResponse<T> = await api(config);

    //console.log(`Request: ${config.method}`, response);

    return response.data;
   
    
    // Use the created instance to make the request

  } catch (error) {
    handleAxiosError(error);
    // Rethrow the error after handling
    throw error;
  }
};
