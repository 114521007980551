import { Grid, Typography } from "@mui/material";
import ItemCard from "./Card";
import { ListCardsProps } from "../../../types";


  
export const ListCards: React.FC<ListCardsProps> = ({ data }) => {
  return (

    <Grid container spacing={2}>
      {data.length > 0 ? (
        data.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12} // Full width on extra-small screens
            sm={6}  // Half width on small screens (2 cards per row)
            md={3}  // Quarter width on medium screens (4 cards per row)
            lg={3}  // Quarter width on large screens (4 cards per row)
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            <ItemCard item={item} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            No data to display
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};