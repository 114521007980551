import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { ItemCardProps } from '../../../types';
import styles from './cardStyle.module.css';


const ItemCard: React.FC<ItemCardProps> = ({ item }) => {
  return (
      <Card variant="outlined" data-testid="info-card" className={styles.card}>
        <CardContent>
          {Object.entries(item).map(([key, value]) => (
            <Typography key={key} variant="body2" gutterBottom>
              <strong>{formatKey(key)}:</strong> {value}
            </Typography>
          ))}
        </CardContent>
      </Card>
  );
};

// Helper function to format keys
const formatKey = (key: string): string => {
  // Convert snake_case or kebab-case to Title Case
  return key
    .replace(/[_-]/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export default ItemCard;
