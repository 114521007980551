
import { makeRequest } from '../utils/axioRequest';
import { DataItem } from '../types';
import { endPoints } from './endPoints';



/**
 * Upload csv file
 * @param file 
 * @param signal 
 * @returns 
 */
export const uploadCSV = (file: File,  signal?: AbortSignal) => {

  const formData = new FormData();
  formData.append('file', file);

  return makeRequest<void>({
    method: 'POST',
    url: endPoints.files,
    data: formData,
    signal,
  });

};


/**
 * Search users
 * @param query 
 * @param signal 
 * @returns 
 */
export const searchUsers = (query?: string, signal?: AbortSignal) => {

  return makeRequest<{data: DataItem[]}>({
    method: 'GET',
    url: endPoints.users,
    params: { q: query || '' },
    signal,
  });

};
