import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Input } from '@mui/material';
import { fileToFormatCsv } from '../../../utils/formatCSV';
import { CSVUploadProps } from '../../../types';
import { uploadCSV } from '../../../services/api';
import { enqueueSnackbar } from 'notistack';
import styles from '../homeStyle.module.css';

const CSVUpload: React.FC<CSVUploadProps> = ({ setData }) => {
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [abortController, setAbortController] = useState<AbortController | null>(null); // Abort controller to cancel the upload request if needed


  // ------- Clean up on unmount and before starting a new upload -------
  useEffect(() => {
    return () => {
      if (abortController) abortController.abort();
    };
  }, [abortController]);
  // -----------------------------------------------------------------------

  // ------------ Reset the file input value --------------

  const resetInputValue = () => {
    

    if (inputRef.current) inputRef.current.value = '';

  }

  // -------------------------------------------------------


  // --------------- On file change then set the file ----------------------
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {


    if (e.target.files && e.target.files[0]) {

      // Call the handleUpload function with the first file selected
      handleUpload(e.target.files[0]);

    }
  };
  // -----------------------------------------------------------------------

  // ---------- Handle file upload -------------------
  const handleUpload = async (file: File) => {

    // Abort any ongoing upload request
    if (abortController) abortController.abort();

    // set up the abort controller
    const controller = new AbortController();
    setAbortController(controller);

    if (file) {
      setUploading(true);
      try {

        await uploadCSV(file, controller.signal);

        // We can call the server api to get the uploaded data but for this example I am formatting the csv file and setting it as the data

        // File to format CSV
        const csvData = await fileToFormatCsv(file);

        // Set data
        setData(csvData);

        enqueueSnackbar('File uploaded successfully!', { variant: 'success' });

      } catch (error: any) {

        enqueueSnackbar(error, { variant: 'error' });

      } finally {

        setUploading(false);

        resetInputValue();

      }
    }

  };
  // -----------------------------------------------------------------------

  return (
    <div>
      {/* Hidden file input */}
      <Input
        type="file"
        inputProps={{ accept: '.csv', 'data-testid': 'csv-upload-input' }}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        id="csv-upload-input"
        inputRef={inputRef}
      />
      <Button
        variant="contained"
        color="primary"
        data-testid={"upload-button"}
        onClick={() => document.getElementById('csv-upload-input')?.click()}
        disabled={uploading}
      >
        {uploading ? <CircularProgress size={24} className={styles.cirlceProgress} /> : 'Upload CSV'}
      </Button>
    </div>
  );
};

export default CSVUpload;