import { DataItem } from "../types";

/**
 * Csv string to object
 * @param csvText 
 * @returns 
 */
export const formatCSV = (csvText: string): DataItem[] => {
  // Split CSV text into lines
  const lines = csvText.trim().split('\n');
  
  // Extract headers from the first line
  const headers = lines[0].split(',').map(header => header.trim());
  
  // Process the remaining lines
  return lines.slice(1).map(line => {
    // Split each line into values and trim any surrounding whitespace or carriage returns
    const values = line.split(',').map(value => value.trim());

    // Combine headers and values into objects
    return headers.reduce((acc, header, index) => {
      acc[header] = values[index] || ''; // Handle missing values
      return acc;
    }, {} as Record<string, string>);
  });
};


/**
 * Reads the content of a file and returns it as a text string.
 * @param file - The file to read.
 * @returns A promise that resolves with the file content as a string.
 */
function readFileAsText(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject(new Error('Failed to read file as text.'));
      }
    };

    reader.onerror = (error: ProgressEvent<FileReader>) => {
      reject(new Error(`Error reading file: ${error}`));
    };

    reader.readAsText(file);
  });
}



/**
 * Csv file to object
 * @param file 
 * @returns 
 */
  export const fileToFormatCsv = async (file: File): Promise<DataItem[]> => {
    const text = await readFileAsText(file);
    return formatCSV(text);
  }