import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { SearchBarProps } from '../../../types';
import { searchUsers } from '../../../services/api';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import styles from '../homeStyle.module.css';

const SearchBar: React.FC<SearchBarProps> = ({ setData }) => {
  const [query, setQuery] = useState('');
  const [abortController, setAbortController] = useState<AbortController | null>(null); // Abort controller to cancel the upload request if needed
  const [loading, setLoading] = useState(false);
  

  // ------- Clean up on unmount and before starting a new upload -------
  useEffect(() => {
    return () => {
      if (abortController) abortController.abort();
    };
  }, [abortController]);
  // -----------------------------------------------------------------------


  // --------------- On searchbox text change then search for data ----------------------

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const iQuery = e.target.value;
    setQuery(iQuery);

    try {

      // Show the loading spinner
      setLoading(true);

      // Abort any ongoing search request for the previous query
      if (abortController) abortController.abort();

      // set up the abort controller
      const controller = new AbortController();
      setAbortController(controller);

      // Call the searchUsers function with the query
     const dataT = await searchUsers(iQuery, controller.signal);

     const csvData = dataT.data;

      // Set data
      setData(csvData);

      // Hide the loading spinner
      setLoading(false);

    } catch (error: any) {
      
      // if it's axios signal cancel error, ignore it
      if (axios.isCancel(error)) return

      // Hide the loading spinner
      setLoading(false);

      // Show the error message
      enqueueSnackbar(error, { variant: 'error' });

    }


  };

  // -----------------------------------------------------------------------

  return (
    <Box display="flex" alignItems="center">
      <TextField
        className={styles.searchField}
        size="small"
        label=""
        placeholder="Search"
        variant="outlined"
        value={query}
        onChange={handleChange}
        inputProps={{ 'data-testid': 'search-input' }}
      />
      {loading && (
        <Box marginLeft={2}>
          <CircularProgress size={24} className={styles.cirlceProgress} />
        </Box>
      )}
    </Box>
  );

};

export default SearchBar;
