import { Box, CircularProgress, Container, Typography } from '@mui/material';
import CSVUpload from './components/CSVUpload';
import SearchBar from './components/SearchBar';
import { useEffect, useState } from 'react';
import { DataItem } from '../../types';
import { ListCards } from './components/ListCards';
import styles from './homeStyle.module.css';
import { enqueueSnackbar } from 'notistack';
import { searchUsers } from '../../services/api';

export function Home() {

  // We can also use the redux store to store the data and use it in the components, but for this example I will be using the useState hook
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(true);


  // ----------- On mount load the data -------------
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const dataT = await searchUsers();
      setData(dataT.data);
    } catch (error: any) {
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setLoading(false);
    }

  }
  // --------------------------------------------------


  return (
    <>

      <Box className={styles.toolbar}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens, horizontally on larger screens
              alignItems: { xs: 'center', sm: 'stretch' }, // Center on small screens, stretch on larger screens
              justifyContent: { xs: 'center', sm: 'space-between' }, // Center on small screens, space-between on larger screens
              gap: 2, // gap between items
              px: 2 // padding for horizontal spacing
            }}
          >
            <Box
     
            >
              <SearchBar setData={setData} />
            </Box>
            <Box
  
            >
              <CSVUpload setData={setData} />
            </Box>
          </Box>
        </Container>
      </Box>



      <Typography variant="h5" align="center" sx={{ "marginTop": "2rem" }}>CSV Data</Typography>
      <Container sx={{ "marginTop": "1rem", "boxShadow": 3, "padding": 3 }}>

        {
          loading
            ?
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={24} />
            </Box>

            :
            (<ListCards key={"data"} data={data} />)
        }

      </Container>

    </>
  );
}



